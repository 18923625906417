import { useObservableQuery } from '@legendapp/state/react-hooks/useObservableQuery'
import { getQueryKey } from '@trpc/react-query'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { api } from './api'
import { useComputed } from './legendState'
import { useSessionContext } from './supabase/useSessionContext'

export const useUser = () => {
  const router = useRouter()

  const { session, isLoading: isLoadingSession } = useSessionContext()
  const apiUtils = api.useContext()
  const user = session?.user
  const isLogin = !!user
  const [isClient, setIsClient] = useState(false)

  useEffect(() => setIsClient(true), [])

  const user$ = useObservableQuery({
    queryKey: [getQueryKey(api.user.me)],
    queryFn: () => apiUtils.user.me.fetch(),
    enabled: !!user?.id && isClient,
    refetchOnWindowFocus: !!['/billings', '/settings'].some((prefix) =>
      router.pathname.includes(prefix)
    ),
  })

  const userData$ = useComputed(() => user$.get()?.data?.usersCollection?.edges[0]?.node)

  const profile$ = useComputed(() => user$.get()?.data?.usersCollection?.edges[0]?.node.profiles)

  const avatarUrl$ = useComputed(() => {
    const avatarUrl = profile$.avatar_url.get()
    if (avatarUrl) {
      if (avatarUrl.startsWith('http')) {
        return avatarUrl
      }
      return avatarUrl
    }
    return null
  })

  return {
    session,
    user,
    updateProfile: () => user$.refetch(),
    isLoadingSession,
    isLogin,
    user$,
    profile$,
    avatarUrl$,
    userData$,
  }
}
